import axios from "axios";
import React, { useState, useRef, useEffect  } from "react";
import {useNavigate } from 'react-router-dom';
import { Container, Row, Col, ListGroup, Button, Stack, Tab, Tabs } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import './Editor.css';
import { useAuthHeader} from 'react-auth-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGear, faEraser} from '@fortawesome/free-solid-svg-icons'


const backend = process.env.REACT_APP_BACKEND_API;

function Principal() {
  const quillRef = useRef(null); // Referência para o editor Quill
  const authHeader = useAuthHeader(); // Obter o cabeçalho de autenticação
  const token = authHeader(); // Token de autenticação
  const navigate = useNavigate(); // Navegação
  const versao = process.env.REACT_APP_VERSAO; // Variável para versão

  const [texto, setTexto] = useState([""]); // Estado para armazenar o texto
  const [aba, setAba] = useState(0); // Estado para armazenar a aba atual
  const [chatText, setchatText] = useState(["Pergunte ou peça modificações no texto..."]); // Estado para armazenar o texto
  const [chatTextType, setchatTextType] = useState(["assistant"]); // Array para armazenar o tipo de chatText, começando com "assistant"
  const [empty, setEmpty] = useState(true); // Estado para verificar se está vazio
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar o carregamento
  const [isLoading2, setIsLoading2] = useState(false); // Estado para controlar o carregamento
  const [modelList, setModelList] = useState([]); // Lista de modelos
  const [templateList, setTemplateList] = useState([]); // Lista de templates
  const [prompt, setPrompt] = useState(""); // Estado para armazenar o prompt
  
  const chatTextRef = useRef(null);

  const [tabTitles, setTabTitles] = useState(texto.map((_, index) => `Aba ${index + 1}`));

  const handleTabTitleChange = (index, newTitle) => {
    setTabTitles(prevTitles => {
      const updatedTitles = [...prevTitles];
      updatedTitles[index] = newTitle;
      return updatedTitles;
    });
  };

  useEffect(() => {
    if (chatTextRef.current) {
      chatTextRef.current.scrollTop = chatTextRef.current.scrollHeight;
    }
  }, [chatText]);

  useEffect(() => {
    async function fetchData(){
      try {
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        const response = await axios.get(backend + "/models", config);
        setModelList(response.data);
        const response2 = await axios.get(backend + "/templates", config);
        setTemplateList(response2.data);
      } catch (error) {
          console.log(error);
      }
    }
    fetchData();
  }, [token]);
  
  async function processaTexto(nome, regra) {
    setIsLoading(true);
    handleTabTitleChange(aba, nome);    
    let resultText = ''; 
    const datejs = new Date();

    // Formatando para dd/mm/YYYY
    const dia = String(datejs.getDate()).padStart(2, "0");
    const mes = String(datejs.getMonth() + 1).padStart(2, "0"); // Meses começam do 0
    const ano = datejs.getFullYear();
  
    const hoje = `${dia}/${mes}/${ano}`;

    try {
      const response = await axios.post(
        backend + "/gen_old", 
      {
       "prompt": "Utilizando as informações do paciente fornecidas, atue como um auxiliar na organização de prontuários médicos. Utilize apenas as informações que forem dadas, não crie dados novos. Substitua <hoje> pela data de hoje, que é:" + hoje + ". Siga as instruções e modelo: " + regra + " Informações do paciente: ```" + texto + "```",
      },
      {
        "headers": {
        "Authorization": `${token}`,
        },
      }
      );

      resultText = response.data.result.replace(/\n/g, '<br>');
    
      setTexto(prevTexto => {
        const updatedTexto = [...prevTexto];
        updatedTexto[aba] = resultText;
        return updatedTexto;
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  async function processaTexto_new(nome, regra) {
    setIsLoading(true);
    handleTabTitleChange(aba, nome);
    
    let resultText = '';
    try {
      const response = await fetch(backend + "/gen", {
        method: 'POST',
        headers: {
          "Authorization": `${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "prompt": "Utilizando as informações do paciente fornecidas, atue como um auxiliar na organização de prontuários médicos. Utilize apenas as informações que forem dadas, não crie dados novos. " + regra + " Informações do paciente: ```" + texto[aba] + "```",
        })
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const decodedText = decoder.decode(value, { stream: true });
        const dataEntries = decodedText.split("data: ");
        let dataContent = "";
        dataEntries.forEach(entry => {
          dataContent += entry.slice(0, -2); // Remove os 2 últimos caracteres
          dataContent = dataContent.replace("event: done", "")
          dataContent = dataContent.replace(/\n/g, '<br>');
        });
        
        resultText += dataContent
        setTexto(prevTexto => {
          const updatedTexto = [...prevTexto];
          updatedTexto[aba] = resultText;
          return updatedTexto;
        });
        
      }

    } catch (error) {
      console.error(error);
    }
    //handleTextoChange(resultText.replace(/\n/g, '<br>'));
    setIsLoading(false);
  };
  
  function htmlToPlainText(htmlString) {
    // Substitui <p><br></p> por uma linha em branco (\n\n)
    let text = htmlString.replace(/<p>\s*<br\s*\/?>\s*<\/p>/gi, '\n\n');
  
    // Substitui <br> por uma quebra de linha
    text = text.replace(/<br\s*\/?>/gi, '\n');
  
    // Substitui <p> por nada e </p> por uma quebra de linha
    text = text.replace(/<p>/gi, '').replace(/<\/p>/gi, '\n');
  
    // Decodifica entidades HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    let decoded = doc.documentElement.textContent;
  
    // Remove múltiplas quebras extras e faz um trim para manter o formato correto
    decoded = decoded.replace(/\n{3,}/g, '\n\n').trim();
  
    return decoded;
  }

  const handleCopyToClipboard = () => {
    const plaintxt = htmlToPlainText(texto[aba])
    console.log("copy start:" + plaintxt);
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob(
          [texto[aba]],
          { type: "text/html" }
      ),
      "text/plain": new Blob(
          [plaintxt],
          { type: "text/plain" }
      )
    })
    navigator.clipboard.write([clipboardItem]);   
    //[texto[aba].replace(/<\/p>/g, '\n').replace(/<[^>]*>/g, '')]
  };
  
  async function goChat() {
    setchatText(prevChattext => [...prevChattext, prompt]);
    setchatTextType(prevChatTextType => [...prevChatTextType, 'user']);
    setIsLoading2(true);
    try {
      const response = await axios.post(
        backend + "/gen_old", 
        {
          "prompt": `Considere que você é um médico. Considere o texto sobre o paciente que
          está entre as tags <info> e a solicitação do usuário que está nas tags <prompt>, assim como a conversa prévia atual que está
          nas tags <conversa>. Caso a solicitação não tenha a ver com a sua função, negue educadamente. Caso necessite de mais informações,
          pergunte antes de responder. Caso o usuário solicite alguma mudança no texto, retorne a sua resposta nas tags <resposta> e o texto
          atualizado entre as tags <novo_texto>.
          <conversa>${chatText}</conversa>
          <info>${texto}</info>
          <prompt>${prompt}</prompt>`,
          "model":"gpt-4o",
        },
        {
          "headers": {
            "Authorization": `${token}`,
          },
        }
      );
      const respostaRegex = /<resposta>(.*?)<\/resposta>/s;
      const novoTextoRegex = /<novo_texto>(.*?)<\/novo_texto>/s;

      const respostaMatch = response.data.result.match(respostaRegex);
      const novoTextoMatch = response.data.result.match(novoTextoRegex);

      const resposta = respostaMatch ? respostaMatch[1] : "";
      const novoTexto = novoTextoMatch ? novoTextoMatch[1] : "";

      if (novoTexto) {
        setTexto(prevTexto => {
          const updatedTexto = [...prevTexto];
          updatedTexto[aba] = novoTexto.replace(/\n/g, '<br>');
          return updatedTexto;
        });
      }

      if (resposta) {
        setchatText(prevChattext => [...prevChattext, resposta.replace(/\n/g, '<br>')]);
        setchatTextType(prevChatTextType => [...prevChatTextType, 'assistant']);
      } else {
        setchatText(prevChattext => [...prevChattext, response.data.result.replace(/\n/g, '<br>')]);
        setchatTextType(prevChatTextType => [...prevChatTextType, 'assistant']);
      }
      //setchatText(prevChattext => prevChattext + '<p class="user">' + prompt + '</p>' + '<p class="assistant">' + response.data.result.replace(/\n/g, '<br>') + '</p>');
      setPrompt("");
    } catch (error) {
      console.error("Erro ao gerar texto:", error);
    }
    setIsLoading2(false);
  };

  const goChat_new = async () => {
    setchatText(prevChattext => [...prevChattext, prompt]);
    setchatTextType(prevChatTextType => [...prevChatTextType, 'user']);
    setIsLoading2(true);
      
    try {
      console.log("Enviando requisição para o backend com o prompt:", prompt);
      const response = await fetch(backend + "/gen", {
        method: 'POST',
        headers: {
          "Authorization": `${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "prompt": `Considere que você é um médico. Considere o texto sobre o paciente que
          está entre as tags <info> e a solicitação do usuário que está nas tags <prompt>, assim como a conversa prévia atual que está
          nas tags <conversa>. Caso a solicitação não tenha a ver com a sua função, negue educadamente. Caso necessite de mais informações,
          pergunte antes de responder. Sempre retorne a resposta dentro das tags <resposta>, mas nunca o novo_texto dentro da resposta!. Sempre dê alguma resposta para indicar que entendeu o comando.
          Caso o usuário solicite alguma mudança no texto ou solicite para escrever um texto, retorne a sua resposta nas tags <resposta> e o texto
          atualizado entre as tags <novo_texto>. Aceite comandos como apagar ou remover o texto, nesse caso, retorne a tag <novo_texto> vazia.
          <conversa>${chatText}</conversa>
          <info>${texto[aba]}</info>
          <prompt>${prompt}</prompt>`,
          "model": "gpt-4o",
        })
      });
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let currentStreamText = ''; // Estado para armazenar o texto atual do stream

      setchatText(prevChattext => [...prevChattext, '']);
      setchatTextType(prevChatTextType => [...prevChatTextType, 'assistant']);
  
      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          console.log("Recebido chunk do stream:", chunk);
          const dataEntries = chunk.split("data: ");
          let dataContent = "";
          dataEntries.forEach(entry => {
            dataContent += entry.slice(0, -2); // Remove os 2 últimos caracteres
          });

          if (!dataContent.includes("event: done")) {

            currentStreamText += dataContent;
            console.log("Texto atual do stream:", currentStreamText);

            if (currentStreamText.includes("<resposta>")) {
              const respostaStart = currentStreamText.indexOf("<resposta>") + "<resposta>".length;
              const respostaEnd = currentStreamText.indexOf("</resposta>");
              let resposta = ''; // Inicializa a variável para armazenar a resposta
              resposta = respostaEnd > respostaStart ? currentStreamText.substring(respostaStart, respostaEnd) : currentStreamText.substring(respostaStart);
              
              setchatText(prevChattext => {
                const updatedChatText = [...prevChattext];
                const lastIndex = updatedChatText.length - 1;
                updatedChatText[lastIndex] = resposta.replace(/\n/g, '<br>');
                return updatedChatText;
              });
              console.log("Resposta atualizada no chat:", resposta);
            }
            if (currentStreamText.includes("<novo_texto>")) {
              const novoTextoStart = currentStreamText.indexOf("<novo_texto>") + "<novo_texto>".length;
              const novoTextoEnd = currentStreamText.indexOf("</novo_texto>");
              let novoTexto = ''; // Inicializa a variável para armazenar o novo texto
              novoTexto = novoTextoEnd > novoTextoStart ? currentStreamText.substring(novoTextoStart, novoTextoEnd) : currentStreamText.substring(novoTextoStart);
              setTexto(prevTexto => {
                const updatedTexto = [...prevTexto];
                updatedTexto[aba] = novoTexto.replace(/\n/g, '<br>');
                return updatedTexto;
              });
              console.log("Novo texto atualizado:", novoTexto);
            }
          }
        }
      }

     setPrompt("");
    } catch (error) {
      console.error("Erro ao gerar texto:", error);
    }
    setIsLoading2(false);
  };

  useEffect(() => {
    const regex = /<\s*[^>]*>/g;
    const onlyTags = texto[aba].replace(regex, "").trim() === "";
    setEmpty(texto[aba] === "" || onlyTags);
  }, [texto, aba]);

  // Função UNDO
 const [history, setHistory] = useState([""]);
 /*
  const handleUndo = () => {
    if (history.length === 0) return;

    const newHistory = [...history];
    const lastState = newHistory.pop();

    setTexto(lastState);
    setHistory(newHistory);
  };*/

  const handleTextoChange = (value) => {
    //setHistory([...history, texto]);
    setTexto(prevTexto => {
      const novoTexto = [...prevTexto];
      novoTexto[aba] = value;
      return novoTexto;
    });
  };

  const handleUndo = () => {
  }
  
  return (
    <Container fluid className="mt-2">
      <Row>
        <Col md={2}>
            <ListGroup className='mb-2'>
              <ListGroup.Item variant="success">
                Prompts
                <FontAwesomeIcon className="mx-1 cursor" onClick={() => navigate("/edit/models")} icon={faGear}/>
              </ListGroup.Item>
              {modelList.map((model) => (
                <ListGroup.Item action disabled={empty} onClick={() => processaTexto(model.name, model.data)}>{model.name}</ListGroup.Item>
              ))}
            </ListGroup>
            <ListGroup className='mb-2'>
              <ListGroup.Item variant="success">
                Modelos
                <FontAwesomeIcon className="mx-1 cursor" onClick={() => navigate("/edit/templates")} icon={faGear}/>
              </ListGroup.Item>
              {templateList.map((model) => (
                <ListGroup.Item action onClick={() => {
                  setTexto(prevTexto => {
                    const novoTexto = [...prevTexto];
                    novoTexto[aba] = model.data;
                    return novoTexto;
                  });
                  //handleTextoChange(model.data);
                }}>{model.name}</ListGroup.Item>
              ))}
            </ListGroup>
            <ListGroup className='mb-2'>
                <ListGroup.Item variant="success">Edição</ListGroup.Item>
                <ListGroup.Item action onClick={() => {
                  alert(`Aba: ${aba}\nTexto: ${texto[aba]}`);
                }}>Debug</ListGroup.Item>
                <ListGroup.Item action onClick={handleCopyToClipboard}>Copiar</ListGroup.Item>
                <ListGroup.Item action onClick={handleUndo} disabled={history.length <= 1}>Desfazer</ListGroup.Item>
                <ListGroup.Item action onClick={() => {
                  setTexto(prevTexto => {
                    const novoTexto = [...prevTexto];
                    novoTexto[aba] = "";
                    return novoTexto;
                  });
                }}>Excluir</ListGroup.Item>                
            </ListGroup>
        </Col>
        <Col md={7} className="px-1">
            <Row className="mb-2">
              <Tabs 
                activeKey={`tab${aba + 1}`} 
                id="editor-tabs" 
                className="mb-3"
                onSelect={(selectedKey) => {
                  if (selectedKey === "tab_mais") {
                    const novoTitulo = `Aba ${texto.length + 1}`;
                    setTabTitles([...tabTitles, novoTitulo]);
                    setTexto([...texto, ""]);
                    setAba(texto.length);
                  } else  if (selectedKey === "tab_menos") {
                    if (texto.length > 1) {
                      setTexto(prevTexto => {
                        const novoTexto = [...prevTexto];
                        novoTexto.splice(aba, 1);
                        return novoTexto;
                      });
                      setTabTitles(prevTitles => {
                        const novosTitulos = [...prevTitles];
                        novosTitulos.splice(aba, 1);
                        return novosTitulos;
                      });
                      setAba(prevAba => (prevAba > 0 ? prevAba - 1 : 0));
                    }
                  } else if (selectedKey && selectedKey.startsWith("tab")) {
                    const index = parseInt(selectedKey.replace("tab", ""), 10) - 1;
                    setAba(index);
                  }
                }}
                >
              {texto.map((_, index) => (
                    <Tab 
                      eventKey={`tab${index + 1}`} 
                      title={tabTitles[index]}
                      disabled={isLoading}
                      key={index}>
                      <div style={{ position: 'relative' }}>
                        <ReactQuill
                          ref={quillRef}
                          onChange={handleTextoChange}
                          value={texto[index]}
                          disabled={isLoading}
                          modules={{
                            toolbar: [
                              ['bold', 'italic', 'underline'],
                            ],
                          }}
                          formats={[
                            'bold', 'italic', 'underline',
                          ]}
                        />
                {isLoading && (
                  <div className="loading">
                    <img src="./loading.svg" alt="loading" />
                  </div>
                )}</div>
                    </Tab>
                  ))}
                  <Tab eventKey="tab_mais" title="+"></Tab>
                  <Tab eventKey="tab_menos" title="-"></Tab>
              </Tabs>
            </Row>
            <Row>
              <footer>
                <p className="text-center"><small>⚠ Aviso: textos gerados por inteligência artificial podem conter informações imprecisas ou incorretas. É sua responsabilidade checar as informações antes de usá-las em um contexto real.</small></p>
                <p className="text-center"><small>Versão {versao} - &copy;2024 <a href="http://www.tbmed.com.br">TBMed Serviços Médicos</a> LTDA.</small></p>
              </footer>
            </Row>
        </Col>
        <Col md={3}>
          <Stack gap={0}>
            <div className="chat">
              <h1>Chat <FontAwesomeIcon className="mx-1 cursor" onClick={() => { setchatText(['Pergunte ou peça modificações no texto...']); setchatTextType(['assistant']); }} icon={faEraser}/></h1>
              <div className="chattext" ref={chatTextRef}>
                {chatText.map((text, index) => (
                  <p key={index} className={chatTextType[index]} dangerouslySetInnerHTML={{ __html: text }}></p>
                ))}
              </div>
              {isLoading2 && <p className="chatloading">Carregando...</p>}
              </div>
              <textarea 
                  rows="2" 
                  className="form-control chatinput" 
                  placeholder="Digite aqui e tecle Enter..." 
                  value={prompt} 
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      goChat();
                      e.preventDefault();
                    }
                }}
              ></textarea>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default Principal;